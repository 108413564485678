import request from '../lib/request'
import Service from '../lib/service'
import t from '../lib/i18n'
import config from '../lib/config'
import { cache } from '../lib/service-cache'

const SERVICE_POLL_INTERVAL_SECONDS = 60 * 30 // 30 minutes

class SwitcherService extends Service {
  constructor() {
    super()
    this.buttonText = t('SWITCHER.teams')
    this.label = ''
    this.menus = []
  }

  @cache({ expiresInSeconds: SERVICE_POLL_INTERVAL_SECONDS })
  getOrgMenu() {
    if (this.orgMenuPromise) return this.orgMenuPromise
    return this.orgMenuPromise = this.getMenu('organizations', 3, 3, 'organizations/mine', 'parseOrg')
  }

  @cache({ expiresInSeconds: SERVICE_POLL_INTERVAL_SECONDS })

  getTeamMenu() {
    if (this.teamMenuPromise) return this.teamMenuPromise
    return this.teamMenuPromise = this.getMenu('teams', 3, 1, 'v3/teams/mine?include_favorites=1&with_retired=1&originator_system=Ngin,TeamService,StatNgin', 'parseTeam')
  }

  getMenu(key, showCount, viewAllThreshold, url, parser) {
    const menu = {
      showCount: showCount,
      viewAllThreshold: viewAllThreshold,
      viewAllUrl: `${config.urls.snFrontend}/user/${key}`,
      title: t(`SWITCHER.${key}`),
      key: `${key[0].toUpperCase()}${key.substring(1)}`,
      viewAll: t(`SWITCHER.${key}_view_all`)
    }

    this.menus.push(menu)

    return request
      .get(url)
      .then((data) => {
        menu.items = data.map(item => this[parser](item)).filter(item => !!item)

        if (!menu.items.length) {
          const index = this.menus.indexOf(menu)

          if (index !== -1) {
            this.menus.splice(index, 1)
          }
        }
      })
  }

  parseOrg(org) {
    this.buttonText = t('SWITCHER.organizations')
    this.mobileButtonText = t('SWITCHER.organizations_mobile')
    if (org.organization_types !== 'Team'){
      return {
        title: org.name,
        // description: // TODO: find role for org
        avatar: {
          identity: org.name,
          type: 'organization',
          imgUrl: (org.logo && !org.logo.is_default) ? org.logo.large_logo_url : null,
          size: 300 // 48px
        },
        url: `${config.urls.snFrontend}/org/${org.id}`
      }
    }
  }

  parseTeam(team) {
    const url = `${config.urls.snFrontend}${this.setTeamURL(team)}`
    const season = team.program_secondary_text
    const personaNames = (team.trigger_roster_personas || []).map((p) => p.nickname).join(', ')
    return {
      title: team.name,
      subtitle: team.org_details && team.org_details.name,
      descriptionList: [season],
      avatar: {
        imgUrl: team.logo && team.logo.image_urls && team.logo.image_urls.large || null,
        identity: team.name,
        type: team.originator_type,
        sport: team.sport,
        color: team.primary_color,
        size: 300 // 48px
      },
      url,
    }
  }

  setTeamURL(team) {
    if (team.originator_system === 'Ngin') {
      return `/teams/team-instance-redirect/${team.originator_id}`
    }
    return `/teams/${team.id}`
  }
}

const switcherService = new SwitcherService()

export default switcherService
