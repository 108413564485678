import Component from '../lib/component'
import { allowOtherTouchScrolling, preventOtherTouchScrolling, resetScroll } from '../lib/scroll-utility'

const MAIN_SCROLL_SELECTOR = '.nb-menu-container .nb-menu-body'
const SCROLL_SELECTOR = '.nb-menu-body'

class Dropdown extends Component {
  constructor(options) {
    super(options)
    this.active = false
    this.closeOnClick = {}
    this.mainScrollSelector = `${this.selector} ${MAIN_SCROLL_SELECTOR}`
    this.scrollSelector = `${this.selector} ${SCROLL_SELECTOR}`
  }

  init() {
    // detect a click occuring on an iframe and close the menu
    setInterval(() => ((document.activeElement || {}).tagName !== 'IFRAME') || this.close(), 100)
    document.addEventListener('scroll', this.closeOnOtherScroll, true)
    this.on('destroy', this.destroy)
  }

  get eventBindings() {
    return [
      this.win, 'resize', this.close
    ]
  }

  open() {
    if (this.active) return
    resetScroll(this.mainScrollSelector)
    preventOtherTouchScrolling(this.scrollSelector, { 'when': this.isFullScreen })
    this.active = true
    this.element.classList.remove('nb-menu-dropdown--closed')
    this.element.classList.add('nb-menu-dropdown--open')
    document.documentElement.classList.add('se-bar--dropdown-open', `dropdown-for-${this._parent._elementId}`)
    this._parent.activate()
    this.emit('open')
    this.root.closeMobileMenu()
  }


  close() {
    if (!this.element || !this.active) return
    allowOtherTouchScrolling(this.scrollSelector)
    this.active = false
    this.element.classList.remove('nb-menu-dropdown--open')
    this.element.classList.add('nb-menu-dropdown--closed')
    const { classList: docClassList } = document.documentElement
    docClassList.remove(`dropdown-for-${this._parent._elementId}`)
    const otherDropdownOpen = Array.from(docClassList.values()).find((value) => value.includes('dropdown-for-'))
    if (!otherDropdownOpen) {
      docClassList.remove('se-bar--dropdown-open')
    }
    this._parent.deactivate()
    this.emit('close')
  }

  closeOnOtherScroll(e) {
    if (!this.element || this.element.parentNode.contains(this.element, e.target)) return
    this.close()
  }

  isFullScreen() {
    return this.element && this.element.offsetWidth === window.innerWidth
  }

  destroy() {
    this.close()
    document.removeEventListener('scroll', this.close, true)
  }
}

export default Dropdown
