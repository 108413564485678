import config from './config'

export class PeopleAndOrgsLoader {

  public loadAccountMenu(): Promise<any> {
    return this.loadScripts('se-account-menu')
  }

  private loadScripts(elementName: string): Promise<any> {
    return new Promise((resolve) => {
      if (document.getElementById(elementName)) {
        resolve(null)
        return
      }

      ['runtime', 'polyfills', 'element'].forEach((name) => {
        if (name === 'polyfills' && !!window['Zone']) {
          return
        }
        const src = `${config.urls.peopleAndOrgsUrl}/${elementName}/${name}.js`
        const script = document.createElement('script')
        script.src = src
        script.type = 'module'
        script.async = false
        if (name === 'element') {
          script.id = elementName
          script.addEventListener('load', resolve)
        }
        document.body.append(script)
      })
    })
  }

}
