import Component from '../lib/component'
import { PeopleAndOrgsLoader } from '../lib/people-and-orgs-loader'
import { flushCache } from '../lib/service-cache'
import { SessionStateManager, SignOutReason } from '../session-state'
import authUrls from '../lib/auth-urls'
const { templateFn } = require('./account-menu.ejs')

export class AccountMenu extends Component {

  constructor(parent: Component) {
    super({ parent })
    this.templateFn = templateFn
  }

  public async init(): Promise<void> {
    const loader = new PeopleAndOrgsLoader()
    await loader.loadAccountMenu()
    const opts = {
      signOutUrl: authUrls.getSignOutUrl()
    }
    const element = document.getElementById('se-account-menu')
    element.setAttribute('options', JSON.stringify(opts))
    element.addEventListener('signingOut', this.signingOut)
    element.addEventListener('click', () => this.addSeBarDropdownClass())
  }

  public signingOut(): void {
    flushCache()
    if (SessionStateManager.instance) {
      SessionStateManager.instance.auth.signOut(SignOutReason.userSignOut)
    }
  }

  private addSeBarDropdownClass(): void {
    document.documentElement.classList.add('se-bar--dropdown-open', 'dropdown-for-account-menu')
    this.removeSeBarDropdownClass()
  }

  private removeSeBarDropdownClass(): void {
    const { classList } = document.documentElement as any
    const intervalId = setInterval(() => {
      const menuElement = document.getElementsByTagName('se-account-menu-element')[0]
      if (menuElement && !menuElement['menu'].visible) {
        classList.remove('dropdown-for-account-menu')
        const otherDropdownOpen = Array.from(classList.values()).find((value: any) => value.includes('dropdown-for-'))
        if (!otherDropdownOpen) {
          classList.remove('se-bar--dropdown-open')
        }
        clearInterval(intervalId)
      }
    }, 1000)
  }

}
